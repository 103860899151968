<template>
  <v-container id="issue-tables" fluid tag="section">
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('issue.title')"
      class="px-5 py-3"
    >
      <v-col class="text-right">
        <dialog-message
          :dialog_message="dialog_message"
          :message="message"
        ></dialog-message>
        <v-btn color="aqua" class="mx-2" @click="back()">{{ $t("common.back") }}</v-btn>
        <v-btn color="primary" dark class="mx-2" @click="checkQuota()">
            <v-icon left>mdi-pencil-plus</v-icon>
            {{ $t("issue.createIssue") }}
        </v-btn>
      </v-col>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :footer-props="footerProps"
        :loading="loading"
        multi-sort
      >
        <template v-slot:item.actions="{ item }">
          <v-flex>
            <v-btn
              color="primary"
              class="mx-2"
              small
              @click="showDetail(item, meeting_id)"
            >{{ $t("common.profile") }}</v-btn>
            <v-btn color="ocean" class="mx-2"
              @click="startVoting(item, meeting_id)" small>{{ $t("vote.startVoting") }}</v-btn>
            <v-btn color="navy" class="mx-2"
              @click="showVoteRecord(item, meeting_id)" small>{{ $t("vote.voteRecord") }}</v-btn>
            <!-- <v-btn color="red" class="mx-2" small
            @click="RemoveIssue(item)"
            >{{
              $t("common.delete")
            }}</v-btn> -->
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-2"
                  color="#ff9800"
                  v-bind="attrs"
                  v-on="on"
                  small
                >
                  {{ $t("common.other") }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item-group
                  v-model="selectedItem"
                  color="navy"
                >
                  <v-list-item
                    v-for="(action, index) in other_actions"
                    :key="index"
                    @click="runAction(action.type, item)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ action.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-flex>
        </template>
        <template v-slot:top="{ pagination, options, updateOptions }">
          <v-data-footer
            dense
            :show-first-last-page="footerProps.showFirstLastPage"
            :show-current-page="footerProps.showCurrentPage"
            :first-icon="footerProps.firstIcon"
            :last-icon="footerProps.lastIcon"
            :prev-icon="footerProps.prevIcon"
            :next-icon="footerProps.nextIcon"
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            :itemsPerPageOptions="footerProps.itemsPerPageOptions"
          />
        </template>
        <template slot="no-data">
          <div>
            {{ $t('common.noData') }}
          </div>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css'
import CommonUtil from "@/utils/commonutil.js";
export default {
  data: vm => ({
    dialog_message: false,
    meeting: { title: null},
    items: [],
    totalItems: 0,
    loading: false,
    dialog_form: false,
    options: {},
    footerProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: "mdi-chevron-double-left",
      lastIcon: "mdi-chevron-double-right",
      prevIcon: "mdi-chevron-left",
      nextIcon: "mdi-chevron-right",
      itemsPerPageOptions: [5, 10, 20],
    },
    message: {
      title: "",
      text: ""
    },
    headers: [
      {
        sortable: false,
        text: vm.$i18n.t("issue.name"),
        value: "title"
      },
      {
        sortable: false,
        text: vm.$i18n.t("issue.meeting"),
        value: "meeting.title"
      },
      {
        sortable: false,
        text: vm.$i18n.t("issue.max_elect_count"),
        value: "max_elect_count"
      },
      {
        sortable: false,
        text: vm.$i18n.t("issue.positive_count"),
        value: "positive_count"
      },
      {
        sortable: false,
        text: vm.$i18n.t("issue.alternates_count"),
        value: "alternates_count"
      },
      {
        sortable: false,
        // text: vm.$i18n.t("common.actions"),
        text: '',
        value: "actions"
      }
    ],
    item: {
      title: null,
      meeting: null,
      status: null,
      meeting_time: null,
      max_elect_count: null,
      positive_count: null,
      alternates_count: null
    },
    meetingItems:[],
    selectedItem: null,
    other_actions: [
      {
        title: vm.$i18n.t("issue.record"),
        type: "record"
      },
      {
        title: vm.$i18n.t("common.delete"),
        type: "delete"
      }
    ]
  }),
  components: {
    datetime: Datetime
  },
  computed: {
    ...mapState(["token"])
  },
  methods: {
    ...mapMutations(['setTitle', 'setQuota']),
    close() {
      this.dialog_form = false;
      this.loading = false;
    },
    getApi() {
      this.loading = true;
      const currentObj = this;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/issue/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token
        },
        params: {
            page: page,
            page_size: itemsPerPage,
            meeting: this.meeting_id
        }
      };

      this.axios
        .get(url, config)
        .then(function(response) {
          currentObj.loading = false;
          currentObj.setResult(response.data);
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("get-api-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    setResult(data) {
      let info = JSON.parse(JSON.stringify(data));
      this.totalItems = data.count;
      this.items = data.results;
    },
    showDetail(item, meeting) {
      this.$router.push({
        name: "IssueDetail",
        query: {
          item: item.id,
          meeting: meeting
        }
      });
    },
    checkQuota(){
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/user/getQuota/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token
        },
      };
      this.axios
        .get(url, config)
        .then(function(response) {
          currentObj.loading = false;
          currentObj.setQuota(response.data);
          currentObj.createIssue(response.data)
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("get-api-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    createIssue(data) {
      if(data['max_issue'] < this.totalItems+1){
        this.message.title = this.$i18n.t("common.warning");
        this.message.text = this.$i18n.t("issue.outOfQuota");
        this.dialog_message = !this.dialog_message;
        return
      }
      this.$router.push({
        name: "IssueDetail",
        query: {
          item: null,
          meeting: this.meeting_id
        }
      });
    },
    startVoting(item, meeting){
      this.$router.push({
        name: "Voting",
        query: {
          item: item.id,
          meeting: meeting
        }
      });
    },
    showVoteRecord(item, meeting){
      let routeData = this.$router.resolve({name: 'VoteRecord', query: {
          item: item.id,
          meeting: meeting
        }});
      window.open(routeData.href, '_blank');
      // this.$router.push({
      //   name: "VoteRecord",
      //   params: {
      //     item: item,
      //     meeting: meeting
      //   }
      // });
    },
    back(item){
      this.$router.push({
        name: 'MeetingTables',
        params: {
            // item: item
        }
      });
    },
    RemoveIssue(item){
      if(!confirm(
          this.$i18n.t('common.confirm') + this.$i18n.t('common.delete')+ "？"
        )
      ) {
        return;
      }
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/issue/" +
        item.id +
        "/remove/";
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token
        }
      };
      this.axios
        .patch(url, {}, config)
        .then(function(response) {
          alert("刪除成功。");
          currentObj.getApi();
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("remove-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    runAction(action_type, item){
      if(action_type == "delete"){
        this.RemoveIssue(item)
      } else {
        this.getRecord(item)
      }
      this.selectedItem = null;
    },
    getRecord(item){
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/issue/" +
        item.id +
        "/getResult/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
        responseType: 'arraybuffer'
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.loading = false;
          let blob = new Blob([response.data], {type: "application/octet-stream"});
          let objectUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = objectUrl;
          let date_str = CommonUtil.getDateString();
          let file_name = item.meeting.urban_name + "_" + item.meeting.title + "_" + item.title + "投票結果_"+ date_str +".xlsx"
          link.setAttribute('download', file_name);
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
          currentObj.selectedItem = null;
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("remove-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
          currentObj.selectedItem = null;
        });
    }
  },
  watch: {
    options: {
      handler() {
        this.getApi();
      },
    },
  },
  mounted() {
    this.setTitle(this.$i18n.t('title.IssueList'));
    this.meeting_id = this.$route.query.item;
    // this.getApi();
  }
};
</script>

<style lang="sass" scoped>
.theme--light.v-list-item:hover::before
    opacity: 0.4
.v-list-item--link:before
    background-color: #ff9800
</style>