var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"issue-tables","fluid":"","tag":"section"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-clipboard-text","title":_vm.$t('issue.title')}},[_c('v-col',{staticClass:"text-right"},[_c('dialog-message',{attrs:{"dialog_message":_vm.dialog_message,"message":_vm.message}}),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"aqua"},on:{"click":function($event){return _vm.back()}}},[_vm._v(_vm._s(_vm.$t("common.back")))]),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.checkQuota()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil-plus")]),_vm._v(" "+_vm._s(_vm.$t("issue.createIssue"))+" ")],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"footer-props":_vm.footerProps,"loading":_vm.loading,"multi-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.showDetail(item, _vm.meeting_id)}}},[_vm._v(_vm._s(_vm.$t("common.profile")))]),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"ocean","small":""},on:{"click":function($event){return _vm.startVoting(item, _vm.meeting_id)}}},[_vm._v(_vm._s(_vm.$t("vote.startVoting")))]),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"navy","small":""},on:{"click":function($event){return _vm.showVoteRecord(item, _vm.meeting_id)}}},[_vm._v(_vm._s(_vm.$t("vote.voteRecord")))]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"#ff9800","small":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("common.other"))+" ")])]}}],null,true)},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"navy"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.other_actions),function(action,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.runAction(action.type, item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(action.title))])],1)],1)}),1)],1)],1)],1)]}},{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"dense":"","show-first-last-page":_vm.footerProps.showFirstLastPage,"show-current-page":_vm.footerProps.showCurrentPage,"first-icon":_vm.footerProps.firstIcon,"last-icon":_vm.footerProps.lastIcon,"prev-icon":_vm.footerProps.prevIcon,"next-icon":_vm.footerProps.nextIcon,"pagination":pagination,"options":options,"itemsPerPageOptions":_vm.footerProps.itemsPerPageOptions},on:{"update:options":updateOptions}})]}}])},[_c('template',{slot:"no-data"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('common.noData'))+" ")])])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }